import { createAction, props } from '@ngrx/store';
import { Organization } from '../../model/organization.interface';

export const USER_LOGIN = '[Login Page] Login';
export const USER_LOGIN_SUCCESS = '[Login Page] Login Success';
export const USER_LOGIN_FAILURE = '[Login Page] Login Failure';
export const USER_LOGOUT = '[Auth] Logout';
export const UPDATE_USER_PROFILE = '[Settings] Update User Profile';

export const login = createAction(
  USER_LOGIN,
  props<{ organization: Organization }>()
);

export const loginSuccess = createAction(
  USER_LOGIN_SUCCESS,
  props<{ organization: Organization }>()
);

export const logout = createAction(USER_LOGOUT);

export const updateUserProfile = createAction(
  UPDATE_USER_PROFILE,
  props<{ 
    userName: string;
    userPhone: string; 
  }>()
);