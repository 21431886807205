import { Component, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService, SameSite } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api/menuitem';
import { getLoggedInUser } from 'src/app/auth/store';
import { environment } from 'src/environments/environment';
import { Organization, Tier } from './auth/model/organization.interface';
import { AuthService } from './auth/service/auth.service';
import { loginSuccess, logout } from './auth/store/actions';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { LinkUtils } from './event/util/link.util';
import { BreadcrumbService } from './core/service/breadcrumb.service';
import { catchError, EMPTY, filter, finalize, of, switchMap, tap } from 'rxjs';
import { TokenResponse } from './auth/model/token-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login/service/login.service';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }]
})
export class AppComponent implements OnInit {

  name: string;
  storageValue: number;
  communicationsValue: number;
  segmentsValue: number;
  storageText: string;
  tier: Tier;
  domain: string;
  logoUrl: string;
  breadcrumbs: MenuItem[] = [];
  currentRoute: string = '';
  isSuperAdmin: boolean = false;
  tenants: Organization[] = [];
  impersonatedTenant: Organization | null = null;
  selectedTenantId: string | null = null;
  currentTenantId: string;
  hideMenu: boolean = false;

  items: MenuItem[] = [
    /*{
    label: 'Inicio', routerLink: ['/home'], icon: 'pi pi-home', tabindex: '1', tooltipOptions: {
      tooltipLabel: 'Inicio',
      tooltipPosition: 'bottom'
    }
  },*/
    {
      label: 'Campañas', routerLink: ['/campaigns'], icon: 'pi pi-send', tabindex: '1'
    },
    {
      label: 'Audiencia',  // Nueva categoría
      icon: 'pi pi-users',
      tabindex: '2',
      items: [
        {
          label: 'Fans',
          routerLink: ['/users'],
          icon: 'pi pi-user',
        },
        {
          label: 'Segmentos',
          routerLink: ['/lists'],
          icon: 'pi pi-users',
        }
      ]
    },
/*     {
      label: 'Contenido',  // Nueva categoría
      icon: 'pi pi-file',
      tabindex: '3',
      items: [
        {
          label: 'Plantillas',
          routerLink: ['/templates'],
          icon: 'pi pi-clone',
        }
      ]
    }, */
    {
      label: 'Experiencias', routerLink: ['/events'], icon: 'pi pi-calendar', tabindex: '4'
    },
    {
      label: 'Complementos', routerLink: ['/products'], icon: 'pi pi-shopping-bag', tabindex: '5'
    }];

  constructor(private store: Store, private cookieService: CookieService, private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, private confirmationService: ConfirmationService, private breadcrumbService: BreadcrumbService, private loginService: LoginService) { }

  ngOnInit(): void {

    // Handle breadcrumbs
    this.breadcrumbService.getBreadcrumbs().subscribe((breadcrumbs: any) => {
      this.breadcrumbs = breadcrumbs.map((crumb: any) => {
        return { label: crumb.label, url: crumb.url };
      });
    });

    // Handle route changes
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
      let route = this.activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.hideMenu = route.snapshot.data['hideMenu'] === true;
    });

    // Handle affiliate param
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['affiliate']) {
        const cookiePath = environment.cookie.path;
        const cookieOrigin = environment.cookie.origin;
        const cookieSecure = environment.cookie.secure;
        const cookieExpires = environment.cookie.maxAge;
        const cookieSameSite = environment.cookie.sameSite as SameSite;

        const cookieAffiliate = `X-AFFILIATE=${params['affiliate']};path=${cookiePath};domain=${cookieOrigin};max-age=${cookieExpires};samesite=${cookieSameSite}`;
        document.cookie = cookieAffiliate;
      }
    });

    // Subscribe to user state
    this.store.select(getLoggedInUser).subscribe(observer => {
      if (!observer) return;

      this.currentTenantId = observer.id;
      this.name = observer.userName;
      this.tier = observer.tier;
      this.domain = observer.domain;
      this.logoUrl = observer.logoUrl ?? "/assets/images/sample-logo.webp";

      const usedStorage = Math.round((observer.usedStorage / 1024 / 1024 * 100) / 100).toFixed(2) + ' GB';
      const assignedStorage = Math.round((observer.assignedStorage / 1024 / 1024 * 100) / 100).toFixed(2) + ' GB';

      this.storageValue = 100 * (Math.round(((observer.usedStorage / 1024 / 1024) * 100) / 100)) / (Math.round(((observer.assignedStorage / 1024 / 1024) * 100) / 100));
      this.storageText = `${usedStorage} utilizados de ${assignedStorage}`;

      this.isSuperAdmin = observer.userRole === 'SUPERADMIN';

      if (this.isSuperAdmin) {
        this.loadTenants();
      }
    });
  }

  loadTenants(): void {
    this.authService.getAllTenants().subscribe(tenants => {
      // Sort tenants alphabetically by name
      this.tenants = tenants.sort((a, b) => a.name.localeCompare(b.name));

      // Find the tenant that matches the current user's tenant ID
      const currentTenant = this.tenants.find(tenant => tenant.id === this.currentTenantId);

      if (currentTenant) {
        this.impersonatedTenant = currentTenant;
        this.selectedTenantId = currentTenant.id;
      } else {
        this.impersonatedTenant = null;
        this.selectedTenantId = null;
        console.warn('Current tenant not found in the list');
      }
    });
  }

  onTenantChange(tenantId: string): void {
    const selectedTenant = this.tenants.find(tenant => tenant.id === tenantId);
    if (selectedTenant) {
      this.impersonatedTenant = selectedTenant;
      this.authService.changeTenant(tenantId).pipe(
        tap((tokenResponse: TokenResponse) => {
          this.authService.setTokens(tokenResponse.access_token, tokenResponse.refresh_token);
        }),
        switchMap(() => this.authService.getOrganization()),
        tap(organization => {
          this.store.dispatch(loginSuccess({ organization }));
          this.router.navigate([this.router.url]);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('Failed to change tenant:', error);
          return EMPTY;
        })
      ).subscribe();
    }
  }

  openImprovePlan(): void {
    window.open(environment.info.improvePlan, '_blank');
  }

  logout(): void {
    this.confirmationService.confirm({
      message: '¿Seguro/a que quieres cerrar sesión?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (this.cookieService.check('access_token')) {
          this.loginService.logout()
            .pipe(
              catchError(error => {
                console.error('Error during logout:', error);
                return of(void 0);
              }),
              finalize(() => {
                this.authService.performLogout();
              })
            ).subscribe();
        } else {
          this.authService.performLogout();
        }
      }
    });
  }

  getInitials(name: string): string {
    if (!name) {
      return '';
    }

    const parts: string[] = name.split(' ');
    let initials: string = '';

    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }

    return initials;
  }

  copyPageLink(): void {

    LinkUtils.copy(this.domain);
  }

  openPage(): void {
    window.open(this.domain, '_blank');
  }

  get outletContainerClass() {
    return {
      'p-5': true,
      'overflow-y-scroll': true,
      'no-scroll-attendees': this.currentRoute.includes('/attendees')
    };
  }

}
