import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EventTickets } from '../../model/event-detail.interface';
import { Validators, FormBuilder, AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { getLoggedInUser } from 'src/app/auth/store';
import { DateUtil } from 'src/app/shared/util/DateUtil';
import { CreatedEntity } from '../../model/created-entity.interface';
import { FormPart } from '../../model/form-part.interface';
import { PriceChange } from '../../model/price-change.interface';
import { PriceSimulation } from '../../model/price-simulation.interface';
import { TicketCreate } from '../../model/ticket-create.interface';
import { Ticket } from '../../model/ticket.interface';
import { EventService } from '../../service/event.service';
import { TicketPosition } from '../../model/ticket-position.interface';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-event-detail-tickets',
  templateUrl: './event-detail-tickets.component.html',
  styleUrls: ['./event-detail-tickets.component.scss']
})
export class EventDetailTicketsComponent implements OnInit {

  @Input() startDate: any;
  @Input() endDate: any;
  @Input() eventId: string;
  @Input() tickets: EventTickets[];
  @Output() next: EventEmitter<FormPart> = new EventEmitter();
  @Output() save: EventEmitter<FormPart> = new EventEmitter();

  selectedTicketSold: boolean = false;

  commissionTypes = [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Cantidad fija (€)', value: 'fixed' }
  ];

  maxSellDate: any;
  minSellEndDate: any;

  ticketsSold: boolean = false;

  priceSimulation: PriceSimulation;

  defaultPrice: number = 0;
  includeTaxes: boolean = false;

  previousPrice: number;
  previousDiscount: number;

  display: boolean = false;
  update: boolean = false;
  creatingTicket: boolean = false;

  ticketFee: number;

  uploadedFiles: any[] = [];

  ticketForm = this.fb.group(
    {
      id: this.fb.control('', []),
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control('', [Validators.maxLength(100)]),
      reassignationFee: this.fb.group({
        type: [{value: 'percentage', disabled: false }],
        value: [0],
        enabled: [false],
    }),
      ticketsNumber: this.fb.control(0, [
        Validators.required,
        Validators.max(100000),
      ]),
      regularPrice: this.fb.control(0, [Validators.required]),
      displayPrice: this.fb.control(0, [Validators.required]),
      includeTaxes: this.fb.control(false, []),
      fanDiscount: this.fb.control(0, []),
      sellDate: this.fb.control(new Date(), [Validators.required]),
      sellEndDate: this.fb.control(new Date(), []),
      fanSellDate: this.fb.control(new Date(), []),
      imageUrl: this.fb.control(null),
      assignation: this.fb.group({
        allowDuplicatedEmail: this.fb.control(false),
        fields: this.fb.group({
          name: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          surname: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          phone: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          fiscalId: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          genre: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          birth: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
        }),
        additional: this.fb.array([]),
      }),
      personalizationRequired: this.fb.control(false),
      paymentMethod: ['stripe', Validators.required],
      paymentDirections: [''],
      hasValidityLimit: [false],
      validityStartDate: [null],
      validityEndDate: [null],
    },
    { 
      validators: [
        this.dateValidator,
        this.paymentDirectionsValidator  // Add the new validator
      ]
    }
  );

  msgs: any[];
  descriptionForm: any;
  sortedTickets: EventTickets[];

  paymentMethods = [
    { label: 'Stripe', value: 'stripe' },
    { label: 'Otro', value: 'other' }
  ];
  
  showDirections: boolean = false;

  private initializeReassignationFee(ticket?: EventTickets) {
    return {
      type: ticket?.reassignationFee?.type || 'percentage',
      value: ticket?.reassignationFee?.value ?? 0,
      enabled: ticket?.reassignationFee?.enabled ?? false
    };
  }

  constructor(
    private eventService: EventService,
    private messageService: MessageService,
    private store: Store,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.startDate = this.startDate ? new Date(this.startDate) : null;
    this.endDate = this.endDate ? new Date(this.endDate) : null;

    this.previousPrice = undefined;
    this.previousDiscount = undefined;
    this.store.select(getLoggedInUser).subscribe((observer) => {
      this.ticketFee = observer?.ticketFee;
    });
    this.sortTickets();

    // Initialize hasValidityLimit based on existing dates
    const validityStart = this.ticketForm.get('validityStartDate').value;
    const validityEnd = this.ticketForm.get('validityEndDate').value;
    if (validityStart && validityEnd) {
      this.ticketForm.patchValue({ hasValidityLimit: true });
      this.setValidatorsForValidityFields(true);
    } else {
      this.ticketForm.patchValue({ hasValidityLimit: false });
      this.setValidatorsForValidityFields(false);
    }

  }

  private setReassignationValidators(isUpdate: boolean) {
    const reassignationGroup = this.ticketForm.get('reassignationFee') as FormGroup;
    if (isUpdate) {
        reassignationGroup.get('type').setValidators([Validators.required]);
        reassignationGroup.get('value').setValidators([
            Validators.required,
            Validators.min(0),
            this.commissionValueValidator.bind(this)
        ]);
    } else {
        reassignationGroup.get('type').clearValidators();
        reassignationGroup.get('value').clearValidators();
    }
    reassignationGroup.get('type').updateValueAndValidity();
    reassignationGroup.get('value').updateValueAndValidity();
}

  sortTickets() {
    if (this.tickets) {
      this.sortedTickets = [...this.tickets].sort((a, b) => {
        if (a.displayPosition == null && b.displayPosition == null) {
          return 0;
        }
        if (a.displayPosition == null) {
          return 1;
        }
        if (b.displayPosition == null) {
          return -1;
        }
        return a.displayPosition - b.displayPosition;
      });
    }
  }

  onReorder(event: any): void {
    // The sortedTickets array is already updated by p-orderList
    const updatedPositions: TicketPosition[] = this.sortedTickets.map((ticket, index) => ({
      ticketId: ticket.id!,
      displayPosition: index
    }));

    this.eventService.updateTicketPositions(this.eventId, updatedPositions)
      .pipe(
        finalize(() => {
          // Any cleanup would go here
        })
      )
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Posiciones actualizadas',
            detail: 'Las entradas han sido reordenadas correctamente.'
          });
          // Update the original tickets array with the new order
          this.tickets = [...this.sortedTickets];
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error al actualizar posiciones',
            detail: 'No se pudo guardar el nuevo orden de las entradas. Inténtalo de nuevo.'
          });
          this.sortTickets(); // Revert to the original order
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tickets'] && changes['tickets'].currentValue) {
      this.sortTickets();
    }

    // Handle changes to startDate and endDate if they are @Input
    if (changes['startDate']) {
      this.startDate = changes['startDate'].currentValue ? new Date(changes['startDate'].currentValue) : null;
    }

    if (changes['endDate']) {
      this.endDate = changes['endDate'].currentValue ? new Date(changes['endDate'].currentValue) : null;
    }

    // Update hasValidityLimit based on validityStartDate and validityEndDate
    if (changes['tickets']) { // Assuming validity dates come with tickets
      const validityStart = this.ticketForm.get('validityStartDate').value;
      const validityEnd = this.ticketForm.get('validityEndDate').value;
      if (validityStart && validityEnd) {
        this.ticketForm.patchValue({ hasValidityLimit: true });
        this.setValidatorsForValidityFields(true);
      } else {
        this.ticketForm.patchValue({ hasValidityLimit: false });
        this.setValidatorsForValidityFields(false);
      }
    }
  }

  private setValidatorsForValidityFields(isRequired: boolean) {
    const validityStartControl = this.ticketForm.get('validityStartDate');
    const validityEndControl = this.ticketForm.get('validityEndDate');

    if (isRequired) {
      validityStartControl?.setValidators([Validators.required]);
      validityEndControl?.setValidators([Validators.required]);
    } else {
      validityStartControl?.clearValidators();
      validityEndControl?.clearValidators();
      validityStartControl?.setValue(null);
      validityEndControl?.setValue(null);
    }

    validityStartControl?.updateValueAndValidity();
    validityEndControl?.updateValueAndValidity();
  }

  dateValidator(control: AbstractControl): { [key: string]: any } | null {
    const sellDate = control.get('sellDate')?.value;
    const sellEndDate = control.get('sellEndDate')?.value;
    const hasValidityLimit = control.get('hasValidityLimit')?.value;
    const validityStartDate = control.get('validityStartDate')?.value;
    const validityEndDate = control.get('validityEndDate')?.value;

    let errors: any = {};

    if (sellEndDate && sellDate && sellEndDate < sellDate) {
      errors.sellEndDateInvalid = true;
    }

    if (hasValidityLimit) {
      if (!validityStartDate) {
        errors.validityStartDateRequired = true;
      }
      if (!validityEndDate) {
        errors.validityEndDateRequired = true;
      }
      if (validityStartDate && validityEndDate && validityEndDate < validityStartDate) {
        errors.validityEndDateInvalid = true;
      }
    }

    return Object.keys(errors).length ? errors : null;
  }

  private paymentDirectionsValidator(control: AbstractControl): ValidationErrors | null {
    const paymentMethod = control.get('paymentMethod')?.value;
    const paymentDirections = control.get('paymentDirections')?.value;

    if (paymentMethod === 'other' && (!paymentDirections || paymentDirections.trim() === '')) {
      return { paymentDirectionsRequired: true };
    }

    return null;
  }

  onPaymentMethodChange(event: any) {
    const method = event.value;
    this.showDirections = method === 'other';
    
    if (method === 'stripe') {
      this.ticketForm.patchValue({ paymentDirections: '' });
    }
    
    this.ticketForm.get('paymentDirections')?.updateValueAndValidity();
  }

  nextPage() {
    this.next.emit({
      next: 1,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  prevPage() {
    this.next.emit({
      next: -1,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  setMaxDate($event: any) {
    this.maxSellDate = $event;
  }

  setMinSellEndDate() {
    this.minSellEndDate = this.ticketForm.get('sellDate').value;
  }

  // FIXME refactor unnecessary logic with parent form
  private objectToFormArray(): FormArray {
    let ticketsFormArray: FormArray = this.fb.array([]);

    if (this.tickets) {
      for (const ticket of this.tickets) {
        ticketsFormArray.push(this.createTicketFormGroup(ticket));
      }
    }

    return ticketsFormArray;
  }

  createTicketFormGroup(ticket: Ticket): FormGroup {
    const formGroup: FormGroup = this.fb.group({
      id: this.fb.control('', []),
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control('', []),
      ticketsNumber: this.fb.control(0, [
        Validators.required,
        Validators.max(100000),
      ]),
      regularPrice: this.fb.control(0, [Validators.required]),
      displayPrice: this.fb.control(0, [Validators.required]),
      includeTaxes: this.fb.control(false, []),
      fanDiscount: this.fb.control(0, []),
      sellDate: this.fb.control(new Date(), [Validators.required]),
      sellEndDate: this.fb.control(new Date(), []),
      fanSellDate: this.fb.control(new Date(), []),
      imageUrl: this.fb.control(''),
      assignation: this.fb.group({
        allowDuplicatedEmail: this.fb.control(false),
        fields: this.fb.group({
          name: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          surname: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          phone: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          fiscalId: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          genre: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
          birth: this.fb.group({
            enabled: this.fb.control(false),
            required: this.fb.control(false),
          }),
        }),
        additional: this.fb.array([]),
      }),
      personalizationRequired: this.fb.control(false),
      hasValidityLimit: [ticket.hasValidityLimit || false],
      validityStartDate: [ticket.validityStartDate ? new Date(ticket.validityStartDate) : null],
      validityEndDate: [ticket.validityEndDate ? new Date(ticket.validityEndDate) : null],
      reassignationFee: this.fb.group({
        type: this.fb.control({value: ticket?.reassignationFee?.type || 'percentage', disabled: false }, [Validators.required]),
        value: this.fb.control(ticket?.reassignationFee?.value ?? 0, [Validators.required, Validators.min(0), this.commissionValueValidator.bind(this)]),
        enabled: this.fb.control(ticket?.reassignationFee?.enabled ?? false),
      }),
    }, { validators: this.dateValidator });

    formGroup.patchValue({
      id: ticket.id || '',
      name: ticket.name || '',
      description: ticket.description || '',
      ticketsNumber: ticket.ticketsNumber || 0,
      regularPrice: ticket.regularPrice || 0,
      displayPrice: ticket.displayPrice || 0,
      includeTaxes: ticket.includeTaxes || false,
      fanDiscount: ticket.fanDiscount || 0,
      sellDate: ticket.sellDate ? new Date(ticket.sellDate) : new Date(),
      sellEndDate: ticket.sellEndDate ? new Date(ticket.sellEndDate) : null,
      fanSellDate: ticket.fanSellDate ? new Date(ticket.fanSellDate) : null,
      imageUrl: ticket.imageUrl || '',
      assignation: {
        allowDuplicatedEmail: ticket.assignation?.allowDuplicatedEmail || false,
        fields: {
          name: {
            enabled: ticket.assignation?.fields?.name?.enabled || false,
            required: ticket.assignation?.fields?.name?.required || false,
          },
          surname: {
            enabled: ticket.assignation?.fields?.surname?.enabled || false,
            required: ticket.assignation?.fields?.surname?.required || false,
          },
          phone: {
            enabled: ticket.assignation?.fields?.phone?.enabled || false,
            required: ticket.assignation?.fields?.phone?.required || false,
          },
          fiscalId: {
            enabled: ticket.assignation?.fields?.fiscalId?.enabled || false,
            required: ticket.assignation?.fields?.fiscalId?.required || false,
          },
          genre: {
            enabled: ticket.assignation?.fields?.genre?.enabled || false,
            required: ticket.assignation?.fields?.genre?.required || false,
          },
          birth: {
            enabled: ticket.assignation?.fields?.birth?.enabled || false,
            required: ticket.assignation?.fields?.birth?.required || false,
          },
        },
      },
      personalizationRequired: ticket.personalizationRequired || false,
    });

    // Populate the additional fields
    const additionalFieldsArray = formGroup.get('assignation.additional') as FormArray;
    if (ticket.assignation?.additional && ticket.assignation.additional.length > 0) {
      ticket.assignation.additional.forEach((ad: any) => {
        const fieldGroup = this.fb.group({
          name: [ad.name, Validators.required],
          type: [ad.type, Validators.required],
          required: [ad.required],
          description: [ad.description],
          options: this.fb.array([]),  // Create empty FormArray for options
        });

        // Handle options for select fields
        if (ad.type === 'single-select' || ad.type === 'multi-select') {
          const optionsArray = fieldGroup.get('options') as FormArray;
          if (ad.options && ad.options.length > 0) {
            ad.options.forEach((option: string) => {
              // Ensure that each option is properly initialized as a FormControl
              optionsArray.push(this.fb.control(option, Validators.required));
            });
          }
        }

        additionalFieldsArray.push(fieldGroup);
      });
    }

    return formGroup;
  }

  saveDraft(): void {
    this.save.emit({
      next: 0,
      name: 'tickets',
      data: this.objectToFormArray(),
    });
  }

  addTicket() {
    this.selectedTicketSold = false;
    this.ticketForm.get('ticketsNumber').enable();
    this.update = false;
    this.setReassignationValidators(false);
    this.defaultPrice = 0;
    this.includeTaxes = false;
    this.ticketForm.reset();
    this.ticketForm.patchValue({
      sellDate: new Date(),
      regularPrice: 0,
      displayPrice: 0,
      includeTaxes: false,
    });
    this.display = true;
    this.ticketForm.markAsDirty();
    this.sortTickets();
  }

  myUploader(event: any) {
    const file = event.files[0];

    const imageName: string = self.crypto.randomUUID();
    const mimeType = file.type;

    this.eventService.getSignedUrl(imageName, mimeType).subscribe(signedUrl => {
      this.eventService.uploadImage(signedUrl.signedUrl, mimeType, file).subscribe(response => {
        if (response.status === 200) {
          this.uploadedFiles.push(file);
          this.ticketForm.patchValue({ imageUrl: signedUrl.destinationUrl });
          this.ticketForm.markAsDirty();
        }
      });
    });
  }

  createTicket() {
    this.creatingTicket = true;
    const ticketData = this.ticketForm.value;

    const paymentMethodData = {
      type: ticketData.paymentMethod,
      directions: ticketData.paymentMethod === 'other' ? ticketData.paymentDirections : undefined
    };

    const finalTicketData = {
      ...ticketData,
      paymentMethod: paymentMethodData
    };

    if (this.eventId) {
      // Add new ticket to existing event
      const ticketId = this.ticketForm.get('id').value;

      if (ticketId) {
        this.eventService
          .updateTicket(this.eventId, ticketId, finalTicketData)
          .subscribe(
            () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Entrada modificada correctamente',
                detail: '',
              });
              this.display = false;
              this.creatingTicket = false;
              this.updateTicketInList(finalTicketData);
              // Hotfix to KAN-63. Not good practice, investigate a better solution.
              window.location.reload();
            },
            () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Ha ocurrido un error',
                detail: 'Revisa los datos e inténtalo de nuevo',
              });
              this.creatingTicket = false;
            }
          );
      } else {
        this.eventService
          .addTicket(this.eventId, finalTicketData)
          .subscribe(
            (response: CreatedEntity) => {
              const newTicket: any = { ...ticketData, id: response.created, ticketsSold: 0 };
              if (!this.tickets) this.tickets = [];
              this.tickets.push(newTicket);
              this.display = false;
              this.creatingTicket = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Entrada creada correctamente',
                detail: 'Recarga la página para ver los cambios',
              });
            },
            () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Ha ocurrido un error',
                detail: 'Revisa los datos e inténtalo de nuevo',
              });
              this.creatingTicket = false;
            }
          );
      }
    } else {
      // Just add the ticket to the tickets array
      if (!this.tickets) this.tickets = [];
      this.tickets.push(finalTicketData);
      this.creatingTicket = false;
      this.display = false;
    }
  }

  updateTicketInList(updatedTicket: TicketCreate) {
    const index = this.tickets.findIndex(
      (ticket) => ticket.id === updatedTicket.id
    );
    if (index !== -1) {
      // Manually update the ticket object in the list
      this.tickets[index] = { ...this.tickets[index], ...updatedTicket };
    }
  }

  updateTicket($event: EventTickets) {
    this.update = true;
    this.setReassignationValidators(true);
    this.selectedTicketSold = false;

    const regularPrice = $event.regularPrice / 100;
    const displayPrice = $event.displayPrice / 100;

    this.defaultPrice = $event.includeTaxes ? displayPrice : regularPrice;
    this.includeTaxes = $event.includeTaxes;

    // Initialize reassignation fee with default values if not present
    const reassignationFee = this.initializeReassignationFee($event);

    // Clear existing additional fields
    const additionalFieldsFormArray = this.ticketForm.get('assignation.additional') as FormArray;
    while (additionalFieldsFormArray.length !== 0) {
      additionalFieldsFormArray.removeAt(0);
    }

    // Patch additional fields if they exist
    if ($event.assignation?.additional?.length > 0) {
      $event.assignation.additional.forEach((ad) => {
        const fieldGroup = this.fb.group({
          name: [ad.name, Validators.required],
          type: [ad.type, Validators.required],
          description: [ad.description || ''],
          required: [ad.required],
          options: this.fb.array([])
        });

        // Handle options for single-select and multi-select fields
        if (ad.type === 'single-select' || ad.type === 'multi-select') {
          const optionsArray = fieldGroup.get('options') as FormArray;
          if (ad.options && ad.options.length > 0) {
            ad.options.forEach((option: string) => {
              optionsArray.push(this.fb.control(option, Validators.required));
            });
          }
        }

        additionalFieldsFormArray.push(fieldGroup);
      });
    }

    const hasValidityLimit = !!$event.validityStartDate && !!$event.validityEndDate;

    const validityStartDate = $event.validityStartDate ? DateUtil.fromUTC($event.validityStartDate) : null;
    const validityEndDate = $event.validityEndDate ? DateUtil.fromUTC($event.validityEndDate) : null;

    const paymentMethod = $event.paymentMethod?.type || 'stripe';
    const paymentDirections = $event.paymentMethod?.directions || '';
    
    this.showDirections = paymentMethod === 'other';

    this.ticketForm.patchValue({
      id: $event.id,
      name: $event.name,
      description: $event.description,
      reassignationFee: {
        type: reassignationFee.type,
        value: reassignationFee.value,
        enabled: reassignationFee.enabled,
      },
      imageUrl: $event.imageUrl,
      ticketsNumber: $event.ticketsNumber,
      regularPrice: regularPrice,
      displayPrice: displayPrice,
      includeTaxes: $event.includeTaxes,
      fanDiscount: $event.discount,
      sellDate: DateUtil.fromUTC($event.sellDate),
      sellEndDate: $event.sellEndDate ? DateUtil.fromUTC($event.sellEndDate) : null,
      fanSellDate: $event.fanSellDate ? DateUtil.fromUTC($event.fanSellDate) : null,
      assignation: {
        allowDuplicatedEmail: $event.assignation.allowDuplicatedEmail,
        fields: {
          name: {
            enabled: $event.assignation.fields.name.enabled,
            required: $event.assignation.fields.name.required,
          },
          surname: {
            enabled: $event.assignation.fields.surname.enabled,
            required: $event.assignation.fields.surname.required,
          },
          phone: {
            enabled: $event.assignation.fields.phone.enabled,
            required: $event.assignation.fields.phone.required,
          },
          fiscalId: {
            enabled: $event.assignation.fields.fiscalId.enabled,
            required: $event.assignation.fields.fiscalId.required,
          },
          genre: {
            enabled: $event.assignation.fields.genre.enabled,
            required: $event.assignation.fields.genre.required,
          },
          birth: {
            enabled: $event.assignation.fields.birth.enabled,
            required: $event.assignation.fields.birth.required,
          },
        },
      },
      paymentMethod: paymentMethod,
      paymentDirections: paymentDirections,
      personalizationRequired: $event.personalizationRequired || false,
      hasValidityLimit: hasValidityLimit,
      validityStartDate: validityStartDate,
      validityEndDate: validityEndDate,
    });
    this.onHasValidityLimitChange(hasValidityLimit);

    if (hasValidityLimit) {
      this.ticketForm.get('validityStartDate').setValue(validityStartDate);
      this.ticketForm.get('validityEndDate').setValue(validityEndDate);
    }

    this.maxSellDate = new Date($event.sellDate);
    this.setMinSellEndDate();

    if ($event.ticketsSold > 0) {
      this.selectedTicketSold = true;
    }

    this.ticketForm.markAsDirty();
    this.display = true;
  }

  removeTicketFromList(index: number) {
    if (index !== -1) {
      this.tickets.splice(index, 1);
    }
  }

  onTicketPriceChange($event: PriceChange) {
    this.ticketForm.patchValue({
      regularPrice: $event.basePrice,
      displayPrice: $event.finalPrice,
      includeTaxes: $event.includeTaxes,
    });

    this.ticketForm.markAsDirty();
    this.ticketForm.markAsTouched();
  }

  onHasValidityLimitChange(hasLimit: boolean) {
    if (hasLimit) {
      this.ticketForm.patchValue({
        validityStartDate: this.startDate,
        validityEndDate: this.endDate
      });
      this.ticketForm.get('validityStartDate')?.setValidators([Validators.required]);
      this.ticketForm.get('validityEndDate')?.setValidators([Validators.required]);
    } else {
      this.ticketForm.patchValue({
        validityStartDate: null,
        validityEndDate: null
      });
      this.ticketForm.get('validityStartDate')?.clearValidators();
      this.ticketForm.get('validityEndDate')?.clearValidators();
    }
    this.ticketForm.get('validityStartDate')?.updateValueAndValidity();
    this.ticketForm.get('validityEndDate')?.updateValueAndValidity();
  }

  handleToggleChange(isActive: boolean): void {
    const ticketId = this.ticketForm.get('id').value;
    this.eventService.changeTicketReassignation(this.eventId, ticketId, isActive).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Reasignación actualizado correctamente',
          detail: ''
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'Vuelve a intentarlo en unos minutos'
        });
      }
    );
  }

  commissionValueValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.parent) {
      return null;
    }
  
    const parentGroup = control.parent;
    const enabled = parentGroup.parent?.get('enabled')?.value; // Assuming structure: reassignationFee -> { type, value, enabled }
  
    // If fee is not enabled, skip validation
    if (!enabled) {
      return null;
    }
  
    const commissionType = parentGroup.get('type')?.value;
    const commissionValue = control.value;
  
    if (commissionType === 'percentage') {
      if (commissionValue <= 0) {
        return { minCommission: true };
      } else if (commissionValue > 100) {
        return { maxPercentExceeded: true };
      }
    } else if (commissionType === 'fixed') {
      if (commissionValue < 0) {
        return { minCommission: true };
      }
    }
  
    return null;
  }

}
