import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EventResponse } from 'src/app/event/model/event-detail.interface';
import { EventService } from 'src/app/event/service/event.service';

@Component({
  selector: 'app-event-detail-reassignation',
  templateUrl: './event-detail-reassignation.component.html',
  styleUrls: ['./event-detail-reassignation.component.scss']
})
export class EventDetailReassignationComponent implements OnInit {
  @Input() eventId: string;

  commissionTypes = [
    { label: 'Porcentaje', value: 'percentage' },
    { label: 'Cantidad fija (€)', value: 'fixed' }
  ];

  reassignationFeeForm = new FormGroup({
    reassignationFee: new FormGroup({
      type: new FormControl({value: null, disabled: false }, [Validators.required],),
      value: new FormControl(null, [Validators.required, Validators.min(0), this.commissionValueValidator.bind(this)],),
      enabled: new FormControl(false),
    })
  })

  constructor(private route: ActivatedRoute, private eventService: EventService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = params['id'];
      this.eventService.getEventById(this.eventId).subscribe((response: EventResponse) => {
        this.reassignationFeeForm.patchValue(response.event);
      });
    });
  }

  updateEvent(): void {

    const value = {
      reassignationFee: this.reassignationFeeForm.value.reassignationFee
    };

    this.eventService.updateEvent(this.eventId, value, 'reassignationFee').subscribe(() => {
      this.reassignationFeeForm.reset(this.reassignationFeeForm.value);
      this.messageService.add({ severity: 'success', summary: 'Guardado con éxito', detail: '' });

      this.reassignationFeeForm.markAsPristine();
    }, () => this.messageService.add({ severity: 'error', summary: 'Ha ocurrido un error', detail: 'Revisa los datos y vuelve a intentarlo' }));
  }

  handleToggleChange(isActive: boolean): void {

    this.eventService.changeReassignation(this.eventId, isActive).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Reasignación actualizado correctamente',
          detail: ''
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'Vuelve a intentarlo en unos minutos'
        });
      }
    );
  }

  commissionValueValidator(control: AbstractControl): ValidationErrors | null {

    if (!control.parent) {
      return null;
    }
    const commissionTypes = control.parent.get('type')?.value;
    const commissionValue = control.value;

    if (commissionTypes === 'percentage') {
      if (commissionValue > 100) {
        return { maxPercentExceeded: true };
      }
      if (commissionValue <= 0) {
        return { minCommission: true };
      }
    } else if (commissionTypes === 'fixed') {
      if (commissionValue < 0) {
        return { minCommission: true };
      }
    }

    return null;
}

}
