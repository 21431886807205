<div class="stepsdemo-content table-container">
  <form [formGroup]="reassignationFeeForm">
    <p-card>
      <div formGroupName="reassignationFee">
        <div class="flex align-items-center gap-2">
          <h3 class="m-0">Comisión de reventa</h3>
          <p-inputSwitch formControlName="enabled" (onChange)="handleToggleChange($event.checked)"></p-inputSwitch>
        </div>
        <small class="p-error" *ngIf="!reassignationFeeForm.get('reassignationFee.enabled').value">
          Se aplicará la comisión especificada a nivel global.
        </small>
        <h6 class="mt-3"> Configura el tipo de comisión que se aplicará y el valor </h6>

        <div class="field">
          <label for="commissionTypes" class="block">Tipo de comisión</label>
          <p-dropdown id="commissionTypes" [options]="commissionTypes" formControlName="type" optionLabel="label"
            optionValue="value" class="mb-3"
            [disabled]="!reassignationFeeForm.get('reassignationFee.enabled').value"></p-dropdown>
        </div>

        <div class="field">
          <label for="value">Comisión a aplicar</label>
          <div class="p-inputgroup">
            <p-inputNumber id="value" formControlName="value" [minFractionDigits]="2" [maxFractionDigits]="2"
              [disabled]="!reassignationFeeForm.get('reassignationFee.enabled').value"></p-inputNumber>
            <span class="p-inputgroup-addon">
              {{ reassignationFeeForm.get('reassignationFee.type').value === 'percentage' ? '%' : '€' }}
            </span>
          </div>
          <ng-container *ngIf="reassignationFeeForm.get('reassignationFee.value') as valueControl">
            <small class="p-error"
              *ngIf="valueControl.errors?.['required'] && (valueControl.dirty || valueControl.touched)">
              La comisión es obligatoria.
            </small>
            <small class="p-error" *ngIf="valueControl.errors?.['min'] && (valueControl.dirty || valueControl.touched)">
              La comisión debe ser mayor que 0.
            </small>
            <small class="p-error"
              *ngIf="valueControl.errors?.['maxPercentExceeded'] && (valueControl.dirty || valueControl.touched)">
              La comisión no puede ser mayor al 100%.
            </small>
            <small class="p-error"
              *ngIf="valueControl.errors?.['minCommission'] && (valueControl.dirty || valueControl.touched)">
              La comisión debe ser válida.
            </small>
          </ng-container>
        </div>


        <div class="flex flex-row justify-content-end">
          <button pButton
            [disabled]="!reassignationFeeForm.get('reassignationFee.enabled')?.value || !reassignationFeeForm.dirty || reassignationFeeForm.get('reassignationFee.value')?.invalid"
            label="Guardar cambios" (click)="updateEvent()"></button>
        </div>

      </div>
    </p-card>
  </form>
</div>
