import { createReducer, on } from '@ngrx/store';
import { OnboardingStatus } from '../../model/onboarding-status.interface';
import * as OnboardingActions from '../actions/onboarding.actions';

export interface OnboardingState {
  status: OnboardingStatus | null;
}

export const initialState: OnboardingState = {
  status: null
};

export const onboardingReducer = createReducer(
  initialState,
  on(OnboardingActions.setOnboardingStatus, (state, { status }) => ({
    ...state,
    status
  }))
);