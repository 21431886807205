import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap, timeout, catchError } from 'rxjs/operators';
import { getLoggedInUser } from '../store';
import { InitializationService } from 'src/app/core/service/initialization.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private readonly INIT_TIMEOUT = 10000;

  constructor(
    private store: Store,
    private router: Router,
    private initializationService: InitializationService
  ) {}

  canActivate(): Observable<boolean> {
    return this.initializationService.isInitialized().pipe(
      filter(initialized => initialized),
      timeout(this.INIT_TIMEOUT),
      catchError(error => {
        console.error('Auth guard initialization failed:', error);
        void this.router.navigate(['/error']);
        return of(false);
      }),
      switchMap(() => this.store.select(getLoggedInUser).pipe(take(1))),
      map(user => !!user),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          void this.router.navigate(['/login']);
        }
      })
    );
  }
}