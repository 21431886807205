import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, catchError, filter, map, Observable, of, switchMap, take, tap } from "rxjs";
import { AuthService } from "src/app/auth/service/auth.service";
import { getOnboardingStatus } from "src/app/auth/store";
import { loginSuccess, setOnboardingStatus } from "src/app/auth/store/actions";

@Injectable({ providedIn: 'root' })
export class InitializationService {
  private initialized$ = new BehaviorSubject<boolean>(false);
  private initializing = false;

  constructor(
    private store: Store,
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  isInitialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }

  initializeApp(): Observable<boolean> {
    if (this.initializing) {
      return this.initialized$;
    }

    this.initializing = true;
    const accessToken = this.cookieService.get('access_token');
    const refreshToken = this.cookieService.get('refresh_token');

    if (!accessToken && !refreshToken) {
      this.initialized$.next(true);
      this.initializing = false;
      return of(true);
    }

    if (!accessToken && refreshToken) {
      return this.authService.refreshToken(refreshToken).pipe(
        switchMap(() => this.initializeWithTokens()),
        catchError(() => {
          this.authService.performLogout();
          return of(false);
        })
      );
    }

    return this.initializeWithTokens();
  }

  private initializeWithTokens(): Observable<boolean> {
    return this.authService.getOrganization().pipe(
      switchMap(organization => {
        this.store.dispatch(loginSuccess({ organization }));
        return this.authService.getOnboardingStatus().pipe(
          tap(status => {
            this.store.dispatch(setOnboardingStatus({ status }));
          }),
          switchMap(() => this.store.select(getOnboardingStatus).pipe(
            filter(status => status !== null),
            take(1)
          ))
        );
      }),
      map(() => true),
      catchError(error => {
        console.error('Initialization failed:', error);
        this.authService.performLogout();
        return of(false);
      }),
      tap(() => {
        this.initialized$.next(true);
        this.initializing = false;
      })
    );
  }
}