import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as LoginActions from '../actions/login.actions';

@Injectable()
export class LoginEffects {
    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
          ofType(LoginActions.loginSuccess),
          /*
          // Only navigate if we're currently on the login path
          // This avoids redirections when: Completing onboarding, Changing Tenant (Superadmin), Initializer.
          // Those places are already handled locally
          */
          tap(({ organization }) => {
            if (organization && this.router.url.includes('/login')) {
              void this.router.navigate(['/']);
            }
          })
        ),
        { dispatch: false }
      );

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}