import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
  HttpContextToken
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TokenResponse } from 'src/app/auth/model/token-response.interface';
import { jwtDecode } from 'jwt-decode';

export const SKIP_AUTH_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly TOKEN_COOKIE_KEY = 'access_token';
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private retryCount = 0;
  private readonly MAX_RETRIES = 3;
  private readonly TOKEN_REFRESH_GRACE_PERIOD = 30; // seconds
  
  constructor(
    private readonly cookieService: CookieService,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<unknown>, 
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Skip authentication for specific requests
    if (request.context.get(SKIP_AUTH_INTERCEPTOR)) {
      return next.handle(request);
    }

    return this.handleRequest(request, next);
  }

  private handleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.cookieService.get(this.TOKEN_COOKIE_KEY);

    // If no token exists, proceed without authorization
    if (!accessToken) {
      return next.handle(request).pipe(
        catchError((error) => this.handleError(error, request, next))
      );
    }

    // Check if token is valid
    if (!this.isTokenValid(accessToken)) {
      // Token is invalid or expired, try to refresh
      return this.handleTokenRefresh(request, next);
    }

    // Token is valid, proceed with the request
    const authorizedRequest = this.addAuthorizationHeader(request, accessToken);
    return next.handle(authorizedRequest).pipe(
      catchError((error: unknown) => this.handleError(error, request, next))
    );
  }

  private handleTokenRefresh(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.retryCount++;

      return this.authService.refreshToken().pipe(
        switchMap((token: TokenResponse) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addAuthorizationHeader(request, token.access_token));
        }),
        catchError((error) => {
          this.resetRefreshState();
          this.authService.performLogout();
          return throwError(() => error);
        })
      );
    }

    // Wait for the ongoing refresh to complete
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => {
        return next.handle(this.addAuthorizationHeader(request, token.access_token));
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private isTokenValid(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const expectedIssuer = 'https://nevent.es';
      
      return (
        decoded.iss === expectedIssuer && 
        (decoded.exp * 1000) > (Date.now() + (this.TOKEN_REFRESH_GRACE_PERIOD * 1000))
      );
    } catch {
      return false;
    }
  }

  private handleError(error: unknown, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case HttpStatusCode.Unauthorized:
          if (this.retryCount >= this.MAX_RETRIES) {
            this.resetRefreshState();
            this.authService.performLogout();
            return throwError(() => new Error('Max retry attempts reached'));
          }
          return this.handleTokenRefresh(request, next);
          
        case HttpStatusCode.Forbidden:
          this.authService.performLogout();
          return throwError(() => error);
          
        default:
          return throwError(() => error);
      }
    }
    return throwError(() => error);
  }

  private resetRefreshState(): void {
    this.isRefreshing = false;
    this.refreshTokenSubject.next(null);
    this.retryCount = 0;
  }
}