import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserComponent } from './user.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { PurchaseDetailComponent } from './components/purchase-detail/purchase-detail.component';
import { TokenDetailComponent } from './components/token-detail/token-detail.component';

const routes: Routes = [
  {
    path: '', component: UserComponent,
    data: { breadcrumb: 'Todos los fans' }
  },
  {
    path: ':id', component: UserDetailComponent,
    data: { breadcrumb: 'Fan', breadcrumbParam: 'id' }, children: [
      {
        path: 'summary', component: UserDetailComponent,
        data: { breadcrumb: 'Resumen' }
      },
      {
        path: 'profile', component: UserDetailComponent,
        data: { breadcrumb: 'Datos personales' }
      },
      {
        path: 'public', component: UserDetailComponent,
        data: { breadcrumb: 'Perfil público' }
      },
      {
        path: 'tier', component: UserDetailComponent,
        data: { breadcrumb: 'Clasificación' }
      },
      {
        path: 'tokens', component: UserDetailComponent,
        data: { breadcrumb: 'Entradas' },
        children: [
          {
            path: ':tokenId', component: TokenDetailComponent,
            data: { breadcrumb: 'Entradas', breadcrumbParam: 'tokenId' },
            children: [
              {
                path: 'summary', component: TokenDetailComponent,
                data: { breadcrumb: 'Resumen' },
              },
              {
                path: 'assignation', component: TokenDetailComponent,
                data: { breadcrumb: 'Asignación' },
              },
              {
                path: 'experiences', component: TokenDetailComponent,
                data: { breadcrumb: 'Personalización' },
              },
              {
                path: 'products', component: TokenDetailComponent,
                data: { breadcrumb: 'Complementos' },
              },
              {
                path: 'wallet', component: TokenDetailComponent,
                data: { breadcrumb: 'Cashless' },
              },
              {
                path: 'messages', component: TokenDetailComponent,
                data: { breadcrumb: 'Comunicación' },
              }
            ]
          }
        ]
      },
      {
        path: 'purchases', component: UserDetailComponent,
        data: { breadcrumb: 'Compras' }, children: [{
          path: ':id', component: PurchaseDetailComponent,
          data: { breadcrumbParam: 'id' }
        }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}