import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { EventModule } from './event/event.module';
import { UserModule } from './user/user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ChipModule } from 'primeng/chip';
import { BadgeModule } from 'primeng/badge';
import { StoreModule } from '@ngrx/store';
import * as Sentry from "@sentry/angular";
import { reducers, metaReducers } from './auth/store';
import { ProgressBarModule } from 'primeng/progressbar';
import { TagModule } from 'primeng/tag';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { environment } from 'src/environments/environment';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrderListModule } from 'primeng/orderlist';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { EffectsModule } from '@ngrx/effects';
import { OnboardingEffects } from './auth/store/effects/onboarding.effects';
import { LoginEffects } from './auth/store/effects/login.effects';
import { InitializationService } from './core/service/initialization.service';
import { initializeAppFactory } from './core/factories/initialize-app.factory';
import { MenuModule } from 'primeng/menu';


@NgModule({
  declarations: [
    AppComponent,
   ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    EventModule,
    UserModule,
    MenubarModule,
    InputTextModule,
    ChipModule,
    BadgeModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analytics.trackingCode),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([LoginEffects, OnboardingEffects]),
    ProgressBarModule,
    TagModule,
    ProgressSpinnerModule,
    ButtonModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    DragDropModule,
    OrderListModule,
    BreadcrumbModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MenuModule
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [InitializationService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
