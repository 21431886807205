import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, filter, map, switchMap, take } from 'rxjs';
import { getOnboardingStatus } from '../store';
import { InitializationService } from 'src/app/core/service/initialization.service';

@Injectable({ providedIn: 'root' })
export class OnboardingGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private initializationService: InitializationService
  ) {}

  canActivate(): Observable<boolean> {
    return this.initializationService.isInitialized().pipe(
        filter(initialized => initialized),
        switchMap(() => this.store.select(getOnboardingStatus)),
        filter(status => status !== null), // Wait for status to be non-null
        take(1),
        map(status => {
          if (!status?.hasPassword) {
            void this.router.navigate(['/login/complete-profile']);
            return false;
          }
          if (!status?.hasTenant) {
            void this.router.navigate(['/auth/complete']);
            return false;
          }
          return true;
        })
      );
    }
}