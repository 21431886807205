import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {CookieService} from "ngx-cookie-service";
import { SKIP_AUTH_INTERCEPTOR } from 'src/app/core/interceptor/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  validateUserEmail(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/admin/init-register`
    return this.http.post<void>(url, data, {context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)});
  }

  validateUserWithPassword(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/admin/login`
    return this.http.post<void>(url, data, {context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)});
  }

  refreshOtp(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/admin/otp-generation`
    return this.http.post<void>(url, data, {context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)});
  }

  validateUserWithOtp(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/admin/otp`;
    return this.http.post<any>(url, data, {context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true)} );
  }

  completeProfile(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/post-register`
    return this.http.put<void>(url, data);
  }

  logout(): Observable<any> {
    const url = `${this.baseUrl}/auth/admin/logout`
    return this.http.post<void>(url, {});
  }

}
