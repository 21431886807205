import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { EventService } from 'src/app/event/service/event.service';
import { ListService } from 'src/app/list/service/list.service';
import { getUniversalSectionSuffixes } from 'src/app/shared/util/MessageUtil';
import { ItemType } from '../../enums/item-type.enum';
import { CampaignService } from 'src/app/campaign/service/campaign.service';

@Component({
  selector: 'app-show-message-dialog',
  templateUrl: './show-message-dialog.component.html',
  styleUrls: ['./show-message-dialog.component.scss']
})
export class ShowMessageDialogComponent implements OnInit {

  @Input() eventId: string;
  @Input() referenceId: string;
  @Input() section: string;
  @Input() show: boolean = false;
  @Output() hide: EventEmitter<boolean> = new EventEmitter();

  message: string = '';
  displayRedirectionDialog: boolean = false;
  services: string[] = [];
  serviceOptions: SelectItem[] = [];
  customRedirection: boolean = false;
  selectedService: string = '/messages';
  loading: boolean = false;
  sending: boolean = false;

  constructor(private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.serviceOptions = getUniversalSectionSuffixes();
    console.log(this.eventId);
  }

  onDialogHide(): void {
    this.hide.emit();
  }

  sendMessage(): void {
    if (this.sending || !this.message || this.message.trim().length === 0 || this.loading) {
      return;
    }
  
    this.loading = true;
    this.sending = true;

    const itemId = this.referenceId;
    const itemType = this.getItemTypeForSection(this.section);

    this.campaignService.sendMessage(itemType, itemId, this.message, this.eventId, this.selectedService)
      .subscribe(
        () => {
          this.hide.emit(true);
          this.loading = false;
          this.sending = false;
        },
        () => {
          this.hide.emit(false);
          this.loading = false;
          this.sending = false;
        }
      );
  }

  private getItemTypeForSection(section: string): ItemType {
    switch (section) {
      case 'ticketType':
        return ItemType.TICKET_TYPE;
      case 'complement':
        return ItemType.COMPLEMENT;
      case 'supplement':
        return ItemType.SUPPLEMENT;
      case 'list':
        return ItemType.SEGMENT;
      default:
        throw new Error(`Unsupported section type: ${section}`);
    }
  }

  showRedirectionDialog(): void {
    if (!this.message || this.message.trim().length === 0) {
      return;  // Do nothing if the message is not valid
    }
    this.displayRedirectionDialog = true;
  }
}
