import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import * as fromLogin from './reducers/login.reducer';
import * as fromOnboarding from './reducers/onboarding.reducer';

export interface State {
    organization: fromLogin.State;
    onboarding: fromOnboarding.OnboardingState;
}

export const reducers: ActionReducerMap<State> = {
    organization: fromLogin.reducer,
    onboarding: fromOnboarding.onboardingReducer
};

const reducerKeys = ['organization', 'onboarding'];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
      keys: reducerKeys,
      rehydrate: true,
      removeOnUndefined: true,
      syncCondition: () => true
    })(reducer);
  }

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {

        return reducer(state, action);
    };
}


export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];

export const getLoginState = createFeatureSelector<fromLogin.State>('organization');
export const getOnboardingState = createFeatureSelector<fromOnboarding.OnboardingState>('onboarding');

export const getLoggedInUser = createSelector(
    getLoginState,
    fromLogin.getLoggedInUser
);

export const getOnboardingStatus = createSelector(
    getOnboardingState,
    state => state.status
);