<div class="stepsdemo-content">
  <div class="flex flex-row actions-row">
    <button pButton class="p-button-primary cta-button" label="Añadir entrada" icon="pi pi-plus"
      (click)="addTicket()"></button>
  </div>
  <p-card>
    <ng-template pTemplate="title">Entradas</ng-template>
    <ng-template pTemplate="subtitle">
      Configura las diferentes entradas que tiene tu experiencia.
    </ng-template>
    <ng-template pTemplate="content">
      <div class="flex flex-column" *ngIf="sortedTickets && sortedTickets.length > 0; else noTickets">
        <p-orderList [value]="sortedTickets" [dragdrop]="true" (onReorder)="onReorder($event)" [responsive]="true"
          styleClass="ticket-list">
          <ng-template let-ticket pTemplate="item">
            <app-ticket-item [eventId]="eventId" [ticket]="ticket" (onUpdate)="updateTicket($event)"
              (onRemove)="removeTicketFromList($event)">
            </app-ticket-item>
          </ng-template>
        </p-orderList>
      </div>
      <ng-template #noTickets>
        <p>No hay entradas</p>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="grid grid-nogutter justify-content-between"></div>
    </ng-template>
  </p-card>
</div>

<p-sidebar [(visible)]="display" position="right" [baseZIndex]="10000" styleClass="custom-sidebar">
  <ng-template pTemplate="header">
    <h3 *ngIf="ticketForm.get('id').value===null; else updateTicketHeader">Añadir entrada</h3>
    <ng-template #updateTicketHeader>
      <h3>Actualizar entrada</h3>
    </ng-template>
  </ng-template>
  <form [formGroup]="ticketForm">
    <section>
      <h4>Datos básicos</h4>
      <p class="sub">Completa los datos básicos de esta entrada</p>
      <div class="field">
        <label for="name">Nombre *</label>
        <input id="name" formControlName="name" type="text" pInputText
          class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
        <small *ngIf="
                    this.ticketForm.get('name')?.invalid &&
                    (this.ticketForm.get('name')?.dirty ||
                      this.ticketForm.get('name')?.touched)
                  " id="name-help" class="p-error block">El nombre es obligatorio</small>
      </div>
      <div class="field">
        <label for="itemDescription">Descripción</label>
        <p-editor formControlName="description" [style]="{ height: '200px' }">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <select class="ql-header">
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
                <option value="false" selected></option>
              </select>
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <button type="button" class="ql-strike" aria-label="Strike"></button>
              <button type="button" class="ql-list" value="ordered"></button>
              <button type="button" class="ql-list" value="bullet"></button>
            </span> </ng-template></p-editor>
        <small *ngIf="
                    this.ticketForm.get('description')?.invalid &&
                    (this.ticketForm.get('description')?.dirty ||
                      this.ticketForm.get('description')?.touched)
                  " id="name-help" class="p-error block">La descripción no puede tener más de 100 caracteres.</small>
      </div>
      <div class="field flex flex-column">
        <label for="image">Imagen</label>
        <p-image *ngIf="this.ticketForm.get('imageUrl').value" [src]="this.ticketForm.get('imageUrl').value" alt="Image"
          width="250"></p-image>
        <p-fileUpload #fubauto mode="basic" name="myfile[]" accept="image/*" maxFileSize="1000000" [customUpload]="true"
          (uploadHandler)="myUploader($event)" [auto]="true" chooseLabel="Browse"></p-fileUpload>
        <p *ngIf="this.uploadedFiles.length > 0">
          Subido {{ this.uploadedFiles[0].name }} -
          {{ this.uploadedFiles[0].size }} bytes
        </p>
      </div>
    </section>
    <section>
      <h4>Venta</h4>
      <p class="sub">Datos de venta de la entrada</p>
      <div class="field">
        <label for="ticketsNumber">Número de entradas *</label>
        <p-inputNumber id="ticketsNumber" formControlName="ticketsNumber" [disabled]="this.update"
          class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
        <small *ngIf="update" class="text-sm text-gray-600 mt-2 block">
          Si deseas modificar el número de entradas, ve a la sección
          <i class="text-primary">Modificar número de entradas</i>.</small>
        <div *ngIf="
                      this.ticketForm.get('ticketsNumber')?.invalid &&
                      (this.ticketForm.get('ticketsNumber')?.dirty ||
                        this.ticketForm.get('ticketsNumber')?.touched)
                    " id="name-help">
          <small class="p-error block" *ngIf="this.ticketForm.controls.ticketsNumber.errors?.required">
            El número de entradas es obligatorio.
          </small>
          <small class="p-error block" *ngIf="this.ticketForm.controls.ticketsNumber.errors?.max">
            Para crear más de
            {{ this.ticketForm.controls.ticketsNumber.errors?.max.max }} entradas
            contacta con soporte.
          </small>
        </div>
      </div>

      <div *ngIf="update" class="flex flex-column py-4 p" formGroupName="reassignationFee">
        <div class="flex align-items-center gap-4">
          <h4 class="m-0">Reasignación</h4>
          <p-inputSwitch formControlName="enabled" (onChange)="handleToggleChange($event.checked)"></p-inputSwitch>
        </div>
        <small class="p-error" *ngIf="!ticketForm.get('reassignationFee.enabled').value">
          Se aplicará la comisión especificada a nivel de evento o global según configuración.
        </small>
        <h6>Configura la comisión por reasignación de la entrada</h6>
        <div class="flex flex-row gap-3">
          <div class="field w-6">
            <label for="commissionTypes">Tipo de comisión</label>
            <p-dropdown id="commissionTypes" [options]="commissionTypes" formControlName="type" optionLabel="label"
              optionValue="value" class="mb-3"
              [disabled]="!ticketForm.get('reassignationFee.enabled').value"></p-dropdown>
          </div>

          <div class="field w-6">
            <label for="value">Comisión a aplicar</label>
            <div class="p-inputgroup">
              <p-inputNumber id="value" formControlName="value" [minFractionDigits]="2" [maxFractionDigits]="2"
                [disabled]="!ticketForm.get('reassignationFee.enabled').value"></p-inputNumber>
              <span class="p-inputgroup-addon">
                {{ ticketForm.get('reassignationFee.type').value === 'percentage' ? '%' : '€' }}
              </span>
            </div>
            <ng-container *ngIf="ticketForm.get('reassignationFee.value') as valueControl">
              <small class="p-error"
                *ngIf="valueControl.errors?.['required'] && (valueControl.dirty || valueControl.touched)">
                La comisión es obligatoria.
              </small>
              <small class="p-error"
                *ngIf="valueControl.errors?.['min'] && (valueControl.dirty || valueControl.touched)">
                La comisión debe ser mayor que 0.
              </small>
              <small class="p-error"
                *ngIf="valueControl.errors?.['maxPercentExceeded'] && (valueControl.dirty || valueControl.touched)">
                La comisión no puede ser mayor al 100%.
              </small>
              <small class="p-error"
                *ngIf="valueControl.errors?.['minCommission'] && (valueControl.dirty || valueControl.touched)">
                La comisión debe ser válida.
              </small>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="regularPrice">Precio de la entrada *</label>
        <app-price-input [defaultPrice]="defaultPrice" [includeTaxes]="includeTaxes" [fee]="ticketFee" tax="21"
          [disabled]="this.selectedTicketSold" (onPriceChange)="onTicketPriceChange($event)"></app-price-input>
      </div>
      <div class="field">
        <p *ngIf="update && this.selectedTicketSold" class="text-danger">El precio no se puede modificar en
          tickets
          con
          al menos una venta realizada.</p>
      </div>

            <div class="field">
                <label for="paymentMethod">Método de pago *</label>
                <p-dropdown 
                  id="paymentMethod" 
                  [options]="paymentMethods" 
                  formControlName="paymentMethod" 
                  optionLabel="label" 
                  optionValue="value"
                  [style]="{'width':'100%'}"
                  (onChange)="onPaymentMethodChange($event)">
                </p-dropdown>
              </div>
              
              <div class="field" *ngIf="showDirections">
                <label for="paymentDirections">Instrucciones de pago *</label>
                <p-editor 
                  id="paymentDirections"
                  formControlName="paymentDirections" 
                  [style]="{'height':'200px'}"
                >
                  <ng-template pTemplate="header">
                    <span class="ql-formats">
                      <select class="ql-header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option value="false" selected></option>
                      </select>
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline" aria-label="Underline"></button>
                      <button type="button" class="ql-strike" aria-label="Strike"></button>
                      <button type="button" class="ql-list" value="ordered"></button>
                      <button type="button" class="ql-list" value="bullet"></button>
                    </span>
                  </ng-template>
                </p-editor>
                <small 
                  *ngIf="ticketForm.hasError('paymentDirectionsRequired') && ticketForm.get('paymentMethod')?.value === 'other'"
                  class="p-error block"
                >
                  Las instrucciones de pago son obligatorias cuando se selecciona "Otro" como método de pago
                </small>
              </div>            

      <div class="flex flex-row">

        <div class="field flex flex-column  w-6">
          <label for="sellDate">Inicio de venta de entradas *</label>
          <p-calendar appendTo="body" id="sellDate" formControlName="sellDate" dateFormat="dd/mm/yy"
            (onSelect)="setMaxDate($event); setMinSellEndDate()" [showTime]="true" [maxDate]="startDate"
            [disabled]="ticketsSold" [showButtonBar]="true" placeholder="dd/MM/yyyy HH:mm"></p-calendar>
        </div>

        <div class="field flex flex-column  w-6">
          <label for="sellEndDate">Fin de venta de entradas</label>
          <p-calendar appendTo="body" id="sellEndDate" formControlName="sellEndDate" dateFormat="dd/mm/yy"
            [showTime]="true" [minDate]="minSellEndDate" [maxDate]="startDate" [disabled]="ticketsSold"
            [showButtonBar]="true" placeholder="dd/MM/yyyy HH:mm">
            <small *ngIf="
                    ticketForm.hasError('sellEndDateInvalid') &&
                    (ticketForm.get('sellEndDate')?.dirty ||
                    ticketForm.get('sellEndDate')?.touched)
                    " id="sellEndDate-help" class="p-error block">La fecha de fin de venta debe ser posterior a la
              fecha de
              inicio de venta.
            </small>
          </p-calendar>
        </div>
      </div>
    </section>
    <section>
      <h4>Fecha de validez</h4>
      <p class="sub">Configura el momento de inicio y fin en el que la entrada permanecerá válida (ejemplo: Entradas de
        día)</p>
      <div class="field-checkbox">
        <p-checkbox formControlName="hasValidityLimit" binary="true"
          label="Quiero que esta entrada tenga una fecha límite de validación"
          (onChange)="onHasValidityLimitChange($event.checked)"></p-checkbox>
      </div>

      <div *ngIf="ticketForm.get('hasValidityLimit').value">
        <div class="flex flex-row">
          <div class="field flex flex-column w-6">
            <label for="validityStartDate">Inicio de validez *</label>
            <p-calendar appendTo="body" id="validityStartDate" formControlName="validityStartDate" dateFormat="dd/mm/yy"
              [minDate]="startDate" [maxDate]="endDate" [defaultDate]="startDate" [showTime]="true"
              [showButtonBar]="true" placeholder="dd/MM/yyyy HH:mm"></p-calendar>
            <small *ngIf="
                      ticketForm.hasError('validityStartDateRequired') &&
                      (ticketForm.get('validityStartDate')?.dirty ||
                        ticketForm.get('validityStartDate')?.touched)
                    " id="validityStartDate-help" class="p-error block">La fecha de inicio de validez es
              obligatoria.</small>
          </div>
          <div class="field flex flex-column w-6">
            <label for="validityEndDate">Fin de validez *</label>
            <p-calendar appendTo="body" id="validityEndDate" formControlName="validityEndDate" dateFormat="dd/mm/yy"
              [minDate]="ticketForm.get('validityStartDate').value" [maxDate]="endDate" [defaultDate]="endDate"
              [showTime]="true" [showButtonBar]="true" placeholder="dd/MM/yyyy HH:mm"></p-calendar>
            <small *ngIf="
                      ticketForm.hasError('validityEndDateRequired') &&
                      (ticketForm.get('validityEndDate')?.dirty ||
                        ticketForm.get('validityEndDate')?.touched)
                    " id="validityEndDate-help" class="p-error block">La fecha de fin de validez es
              obligatoria.</small>
            <small *ngIf="
                      ticketForm.hasError('validityEndDateInvalid') &&
                      (ticketForm.get('validityEndDate')?.dirty ||
                        ticketForm.get('validityEndDate')?.touched)
                    " id="validityEndDate-invalid-help" class="p-error block">La fecha de fin de validez debe ser
              posterior a la fecha de inicio de validez.</small>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h4>Asignación</h4>
      <p class="sub">Elige qué información quieres solicitar a tus asistentes</p>
      <app-step-tickets-assignation></app-step-tickets-assignation>
    </section>
    <section>
      <h4>Personalización</h4>
      <p class="sub">Configura las opciones de personalización para esta entrada.</p>
      <div class="field-checkbox">
        <p-checkbox formControlName="personalizationRequired" binary="true"
          label="Para adquirir esta entrada es obligatorio seleccionar una personalización con la misma."></p-checkbox>
      </div>
    </section>
    <section>
      <h4>Ventajas para fans</h4>
      <p class="sub">Premia a tus fans con ventajas exclusivas</p>
      <div class="field">
        <label for="fanDiscount">Descuento (%)</label>
        <p-inputNumber id="fanDiscount" suffix="%" formControlName="fanDiscount" [disabled]="ticketsSold"
          class="text-base text-color surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"></p-inputNumber>
      </div>
      <div class="field">
        <label for="fanSellDate">Fecha de venta anticipada</label>
        <div>
          <p-calendar appendTo="body" id="fanSellDate" formControlName="fanSellDate" dateFormat="dd/mm/yy"
            [maxDate]="maxSellDate" [showTime]="true" [disabled]="ticketsSold" [showButtonBar]="true"
            placeholder="dd/MM/yyyy HH:mm"></p-calendar>
        </div>
      </div>
    </section>
  </form>
  <ng-template pTemplate="footer">
    <button pButton *ngIf="!creatingTicket; else createBlocked"
      [disabled]="!ticketForm.valid || !ticketForm.dirty || !ticketForm.touched" class="p-button-success"
      [label]="update ? 'Guardar cambios' : 'Añadir entrada'" (click)="createTicket()"></button>
    <ng-template #createBlocked>
      <button pButton [disabled]="true" class="p-button-success" label="Añadir entrada" icon="pi pi-spin pi-spinner"
        iconPos="right"></button>
    </ng-template>
  </ng-template>
</p-sidebar>
