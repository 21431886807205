import { createAction, props } from '@ngrx/store';
import { OnboardingStatus } from '../../model/onboarding-status.interface';

export const checkOnboardingStatus = createAction('[Auth] Check Onboarding Status');
export const setOnboardingStatus = createAction(
  '[Auth] Set Onboarding Status',
  props<{ status: OnboardingStatus }>()
);

export const passwordCompleted = createAction('[Auth] Password Setup Completed');
export const tenantCompleted = createAction('[Auth] Tenant Setup Completed');