// login.reducer.ts
import { Action, createReducer, on } from '@ngrx/store';
import { Organization } from '../../model/organization.interface';
import * as userActions from '../actions';

export interface State {
  organization: Organization | null;
}

export const initialState: State = {
  organization: null
};

const loginReducer = createReducer(
  initialState,
  on(userActions.loginSuccess, (state, { organization }) => ({
    organization
  })),
  on(userActions.logout, () => initialState),
  on(userActions.updateUserProfile, (state, userProfile) => ({
    ...state,
    organization: state.organization ? {
      ...state.organization,
      userName: userProfile.userName,
      userPhone: userProfile.userPhone
    } : null
  }))
);

export function reducer(state: State | undefined, action: Action): any {
  return loginReducer(state, action);
}

export const getLoggedInUser = (state: State) => state.organization;