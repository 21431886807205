import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceInputComponent } from './components/price-input/price-input.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageModule } from 'primeng/image';
import { FileUploadModule } from 'primeng/fileupload';
import { NameUtil } from './util/NameUtil';
import { SaleDeliveryTableComponent } from './components/sale-delivery-table/sale-delivery-table.component';
import { TableModule } from 'primeng/table';
import { LabelInputComponent } from './components/label-input/label-input.component';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { BadgeModule } from 'primeng/badge';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CreateDialogComponent } from './components/create-dialog/create-dialog.component';
import { InputTextModule } from 'primeng/inputtext';
import { AddProductDialogComponent } from './components/add-product-dialog/add-product-dialog.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomizationDialogComponent } from '../user/components/customization-dialog/customization-dialog.component';
import { ComplementExperienceListComponent } from './components/complement-experience-list/complement-experience-list.component';
import { ShowMessageDialogComponent } from './components/show-message-dialog/show-message-dialog.component';
import { EditorModule } from 'primeng/editor';
import { GenericListItemComponent } from './components/generic-list-item/generic-list-item.component';
import { SidebarModule } from 'primeng/sidebar';
import { MessageComponent } from './components/message/message.component';
import { SkeletonModule } from 'primeng/skeleton';
import { RouterModule } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    PriceInputComponent,
    ImageUploadComponent,
    SaleDeliveryTableComponent,
    LabelInputComponent,
    CreateDialogComponent,
    AddProductDialogComponent,
    CustomizationDialogComponent,
    ComplementExperienceListComponent,
    ShowMessageDialogComponent,
    GenericListItemComponent,
    MessageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputNumberModule,
    ReactiveFormsModule,
    CheckboxModule,
    ImageModule,
    FileUploadModule,
    TableModule,
    DropdownModule,
    TagModule,
    ChipModule,
    BadgeModule,
    ProgressSpinnerModule,
    DialogModule,
    InputTextModule,
    MultiSelectModule,
    EditorModule,
    SidebarModule,
    SkeletonModule,
    RouterModule,
    ConfirmDialogModule,
    MenuModule,
    ToastModule
  ],
  exports: [
    PriceInputComponent,
    ImageUploadComponent,
    SaleDeliveryTableComponent,
    LabelInputComponent,
    CreateDialogComponent,
    AddProductDialogComponent,
    CustomizationDialogComponent,
    ComplementExperienceListComponent,
    ShowMessageDialogComponent,
    GenericListItemComponent,
    MessageComponent,
    BadgeModule,
    ChipModule,
    ConfirmDialogModule,
    MenuModule,
    ToastModule
  ],
  providers: [NameUtil],
})
export class SharedModule {}
