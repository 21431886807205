import { HttpClient, HttpContext, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SKIP_AUTH_INTERCEPTOR } from 'src/app/core/interceptor/auth.interceptor';
import { SignedURL } from 'src/app/event/model/signed-url.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  baseUrl: string = environment.apiUrl


  constructor(private http: HttpClient) { }

  getSignedUrl(imageName: string, mimeType: string): Observable<SignedURL> {

    const url = `${this.baseUrl}/media/upload`

    return this.http.get<SignedURL>(url, { params: { imageName, mimeType } });
  }

  uploadImage(fileuploadurl, contenttype, file): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
        context: new HttpContext().set(SKIP_AUTH_INTERCEPTOR, true) // Skip auth interceptor
      });
    return this.http.request(req);
  }

}
