import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../../service/auth.service';
import { map, switchMap, tap, catchError, filter, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as OnboardingActions from '../actions/onboarding.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getOnboardingStatus } from '..';

@Injectable()
export class OnboardingEffects {
  private updateOnboardingStatus(stage: string) {
    return this.authService.getOnboardingStatus().pipe(
      map(status => OnboardingActions.setOnboardingStatus({ status })),
      catchError(error => {
        console.error(`OnboardingEffects - Error updating status after ${stage}:`, error);
        return of(OnboardingActions.setOnboardingStatus({ status: null }));
      })
    );
  }

  checkStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.checkOnboardingStatus),
      switchMap(() => 
        this.authService.getOnboardingStatus().pipe(
          tap(status => {
            if (!status.hasPassword) {
              void this.router.navigate(['/login/complete-profile']);
            } else if (!status.hasTenant) {
              void this.router.navigate(['/auth/complete']);
            }
          }),
          map(status => OnboardingActions.setOnboardingStatus({ status })),
          catchError(error => {
            console.error('OnboardingEffects - Error fetching status:', error);
            return of(OnboardingActions.setOnboardingStatus({ status: null }));
          })
        )
      )
    )
  );

  passwordCompletedNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.passwordCompleted),
      switchMap(() => this.store.select(getOnboardingStatus).pipe(
        filter(status => status?.hasPassword === true),
        take(1)
      )),
      tap(() => {
        void this.router.navigate(['/home']);
      })
    ),
    { dispatch: false }
  );

  tenantCompletedNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.tenantCompleted),
      switchMap(() => this.store.select(getOnboardingStatus).pipe(
        filter(status => status?.hasTenant === true),
        take(1)
      )),
      tap(() => {
        void this.router.navigate(['/home']);
      })
    ),
    { dispatch: false }
  );

  passwordCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.passwordCompleted),
      switchMap(() => this.updateOnboardingStatus('password'))
    )
  );

  tenantCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.tenantCompleted),
      switchMap(() => this.updateOnboardingStatus('tenant'))
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store
  ) {}
}